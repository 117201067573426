<h1 mat-dialog-title>Pick Your Profile Icon</h1>
<mat-dialog-content class="mat-typography">
  <div fxLayout="column"
       fxLayoutGap="8px">
    <div fxLayout="row">
      <div *ngFor="let icon of iconNames">
        <img [src]="iconsSrcMap[icon]"
             (click)='selectedIcon = icon'
             height="50px"
             width="50px"
             [ngStyle]="{'opacity':selectedIcon === icon ? '100%':'50%'}" />
      </div>
    </div>
    <ng-container *ngIf="type === 'profile'">
      <span>OR</span>
      <button mat-button
              (click)="fileInput.click()">
        <mat-icon>upload</mat-icon>
        Upload Image
      </button>
      <input hidden
             type="file"
             accept="image/*"
             #fileInput
             (change)="onImageUpload($event)" />
    </ng-container>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button type="button"
          class="grey-cancel"
          mat-button
          [mat-dialog-close]="false">
    Cancel
  </button>
  <button color="primary"
          mat-button
          type="button"
          (click)='onSubmit()'
          cdkFocusInitial>
    Confirm
  </button>
</mat-dialog-actions>
