import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconImageResizeData } from './icon-image-resize-dialog-data';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Cropper from 'cropperjs';

@Component({
  selector: 'ehs-icon-image-resize-dialog',
  templateUrl: './icon-image-resize-dialog.component.html',
  styleUrls: ['./icon-image-resize-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconImageResizeDialogComponent implements OnInit, AfterViewInit {
  cropper!: Cropper;
  sanitizedUrl: SafeUrl;
  cropperMode: 'crop' | 'drag' = 'crop';
  constructor(
    public dialogRef: MatDialogRef<IconImageResizeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IconImageResizeData,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(
      this.data.imageFile
    );
  }

  ngAfterViewInit(): void {
    const image = document.getElementById('image') as HTMLImageElement;

    this.cropper = new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
      guides: true
    });
  }

  // Make the crop box rounded

  getRoundedCanvas(sourceCanvas: any) {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();

    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();

    return canvas;
  }

  //Get the cropped image and closes the dialog
  //Returning an url or null if no image

  crop() {
    const croppedCanvas = this.cropper.getCroppedCanvas();
    const roundedCanvas = this.getRoundedCanvas(croppedCanvas);

    const roundedImage = document.createElement('img');

    if (roundedImage) {
      this.dialogRef.close(roundedCanvas.toDataURL());
    } else {
      return this.dialogRef.close(null);
    }
  }

  toDragMode() {
    this.cropperMode = 'drag';
    this.cropper.setDragMode('move');
  }

  toCropMode() {
    this.cropperMode = 'crop';
    this.cropper.setDragMode('crop');
  }

  // Resets the cropper
  reset() {
    this.cropper.clear();
    this.cropper.crop();
  }
}
