<div>
  <div class="mat-body-1"
       fxLayout="row"
       fxLayoutAlign="center">
    <div fxLayout="column"
         fxLayoutAlign="center"
         fxLayoutGap="8px">
      <div *ngIf="icon"
           fxLayout="row"
           fxLayoutAlign="center">
        <mat-icon class="icon-large">
          {{icon}}
        </mat-icon>
      </div>
      <div>
        {{message}}
      </div>
      <div *ngIf="actionMessage"
           fxLayout="row"
           fxLayoutAlign="center">
        <ng-container *ngIf="actionLink else showButton">
          <a mat-flat-button
             [routerLink]="actionLink"
             [color]="actionColor">
            {{actionMessage}}
          </a>
        </ng-container>
        <ng-template #showButton>
          <button mat-flat-button
                  [color]="actionColor"
                  (click)="action">
            {{actionMessage}}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
