import { ObjectId } from 'mongodb';
import { Ethnicities } from '../constants/ethnicities';
import { Languages } from '../constants/languages';
import { Races } from '../constants/races';
import { Company } from './company';
import { DbDocument } from './db-document';
import { HpnImportAudit, HpnImportAuditId } from './hpn/hpn-import-audit';
import { LifePointResponse } from './life-point/life-point-response/life-point-response';
import { LocationData } from './location-data';
import { isPartialUser, PartialUser } from './partial-user';
import { PersonType } from './person-type';
import { UserInsurance } from './user-insurance';
import { UserRegistration } from './user-registration';
import { ProfileIcons } from '../constants/icons';
import { FaxDetails } from './fax-details';

export enum Genders {
  MALE = 'male',
  FEMALE = 'female'
}

export enum SamlStrategy {
  VP = 'vp',
  CBS = 'cbs',
  MICROSOFT = 'microsoft',
  GOOGLE = 'google'
}

// Not Sure what scopes to include for now...
export enum ClientAdminScopes {
  DEFAULT = 'default',
  REG_EXPORT = 'reg_export',
  USER_EXPORT = 'user_export',
  VIEW_USERS = 'view_users',
  VIEW_REGISTRATIONS = 'view_registrations',
  CREATE_REGISTRATION = 'create_registration',
  REMOVE_REGISTRATION = 'remove_registration'
}

/**
 * Filter used to search the users. See more in AdminUserSearchForm & <ehs-user-filters>
 */
export interface UserFilter
  extends Partial<
    Pick<
      User,
      | 'firstName'
      | 'lastName'
      | 'ssn'
      | 'birthDay'
      | 'lastName'
      | 'username'
      | 'shortId'
    >
  > {
  company?: Company | string;
}

// **Note** why isn't weight just part of the user??
// might be refactored later
export type UserWithWeight = User & { weight?: number };

export type UniqueUser = Pick<User, 'birthDay' | 'ssn' | 'lastName'>;

export interface User extends DbDocument, LocationData {
  /**
   * A secondary unique string identifier passed to Lifepoint
   * instead of the mongodb _id. This is due to the
   * requirement that the labs only accepts strings up to 20 characters long,
   * where-as the mongodb id is 24.
   *
   * See #790 for more details
   */
  shortId: string;
  /**
   * Username
   */
  username: string;
  /**
   * Email of the user
   */
  email: string;

  /**
   * The first name of the user
   */
  firstName: string;

  /**
   * The last name of the user
   */
  lastName: string;

  /**
   * Optional, could be blank
   */
  middleName: string;

  /**
   * 4 digit number of SSN
   * Changed to string instead of number to support leading 0s
   */
  ssn: string;

  /***
   * The gender of the user
   * Ex:
   * Male
   * Female
   */
  gender: Genders;

  /**
   * The phone number of the user
   */
  phone: string;

  /**
   * The birthday of the user format should be 'YYYY-MM-DD
   */
  birthDay: string;

  /***
   * The user company
  /**
   * The company the user is a part of.
   */
  company: string | ObjectId | Company;
  /**
   * The list of previous companies the user was a part of.
   */
  prevCompanies?: Array<string | ObjectId | Company>;
  /**
   * Image URL of the user profile stored in Google Cloud Storage
   */
  image: string;
  /**
   * Person Type could be employee, spouse or dependent
   */
  personType: PersonType;
  /**
   * The user's height in feet
   */
  heightFeet: number;
  /**
   * The user's height in inches.
   * Updated from select areas of the app, and used in some BMI
   * calculations
   */
  heightInches: number;
  /**
   * The user's insurance information.
   * This property **is not returned by default**
   */
  insurance?: UserInsurance;
  /**
   * Flag that is returned from the user's session **if**
   * the user is logged in as an admin. This flag by itself
   * doesn't mean much, as the admin session/state should be used
   * to determine who the admin is, and who they are.
   * This is just returned to the client on init load to notify
   * the client to go get the adminUser information from the backend
   */
  isAdmin?: boolean;

  /** Client-Admin properties */
  clientAdmin?: boolean;
  clientAdminScopes?: ClientAdminScopes[];
  clientAdminCompanies?: (Company | string | ObjectId)[];
  /**
   * Property that is true only for "partially"
   * created users. Partially created users only have properties
   * defined in the PartialUser interface.
   */
  partial?: boolean;
  /**
   * If the user activated opt-in sms messaging from EHS via text
   */
  notifyBySms?: boolean;
  /**
   * If the user activated opt-in messaging from EHS via email
   */
  notifyByEmail?: boolean;
  /**
   * If the user failed multiple logins and is locked out of their account.
   */
  locked?: boolean;
  /**
   * The user has any form of MFA
   * */
  hasMFA?: boolean;

  /**
   * Flag to indicate if the user opt out of using MFA,users with this flag will not be forced to set up MFA event if the company requires it
   */
  hasMFADisabled?: boolean;
  /**
   * The race of the user, added with #1626
   */
  race?: Races;
  /**
   * The ethnicity of the user, added with #1626
   */
  ethnicity?: Ethnicities;
  /**
   * The selected default language for exported full reports.
   */
  languagePreference?: Languages;
  /**
   * If the user is flagged for deletion.
   * This is NOT RETURNED BY DEFAULT, be sure to add it to the
   * projection, which should only be available in admin routes.
   */
  flaggedForDeletion?: boolean;
  /**
   * The audit that imported this user. This will "stick around" if the
   * user goes and logs in after being imported via hpn.
   */
  hpn?: HpnImportAuditId | ObjectId | HpnImportAudit;
  /**
   * If the user account was created from the Lifepoint Webhook, then we show
   * the temp-lab-result ID for reference
   */
  lifepoint?: string | LifePointResponse | ObjectId;
  /**
   * Employee ID is provided by some customers to help verify eligibility for those
   * users. This is not required for all customers.
   */
  employeeId?: string;
  /**
   * Relationship ID is set during imports when a user's spouse's information is provided along side the
   * primary user and the spouse already exists in the system.
   */
  relationshipId?: string;
  /**
   * Microsoft ID is the account ID provided when the user signs in using the Microsoft button. This is
   * a unique ID per customer.
   */
  microsoftId?: string;
  /**
   * Google ID is the account ID provided when the user signs in using the Google button. This is
   * a unique ID per customer.
   */
  googleId?: string;

  /**
   * Keep track of the way the user was created, this is just used for troubleshooting
   * so we know if the user signed in using the SAML auth or not.
   */
  samlStrategy?: SamlStrategy;
  /**
   * Should the user's results be excluded from data exports
   */
  restrictData?: boolean;
  /**
   * Date the user's request to restrict their data was triggered in the admin portal
   */
  dateOfDataRestrictionRequest?: Date;
  /**
   * Date when the data restriction should lapse. For now, 1 year from date of restriction request
   */
  dateOfDataRestrictionExpires?: Date;

  /**
   * The user's profile icon, when set to `user`, we will use user uploaded image
   */
  profileIcon?: ProfileIcons;

  /**
   * The URL of the user's profile photo,is used when profileIcon is set to `user`
   */
  profileIconUrl?: string;

  /**
   * Custom field for client to pass in additional data
   */
  clientFilter?: string;
}

/**
 * Special user type used only in some admin
 * endpoints
 */
export type UserWithVerified = User & {
  /**
   * If the user is verified, will be
   * false if the user doesn't exist
   */
  emailVerified: boolean;
  /**
   * If the user is even in firebase or not.
   */
  inFirebase: boolean;
};

/** Used in Client Admin user table */
export type UserWithRegistrations = User & {
  userRegistrations?: UserRegistration;
};

export const isNonPartialUser = (
  user: Partial<PartialUser | User>
): user is User => !isPartialUser(user);

export const isUserWithRegistrations = (
  user: User | UserWithRegistrations
): user is UserWithRegistrations => {
  return 'userRegistrations' in user && !!user.userRegistrations;
};
