import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  getId,
  isDbDocument,
  User,
  UserRegistrationWithResultFlag
} from '@common';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';
import { userRegistrationNgrxActions } from '@ehs-ngrx/user-registration-ngrx';
import {
  EhsAdminRestrictDataDialogService,
  EhsConfirmDialogService,
  EhsEditUserDialogService,
  EhsMergeUserDialogService,
  EhsMoveUserCompanyDialogService,
  EhsPasswordResetDialogService,
  EhsVerifyEmailDialogService
} from '@ehs-ui';
import { AdminUserHttpService } from '@http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { userNgrxActions } from './user-ngrx.actions';
import { UserNgrxFacade } from './user-ngrx.facade';

@Injectable()
export class UserNgrxEffects {
  constructor(
    @Inject(CLIENT_ADMIN_CORE_INJECTION_TOKEN)
    private clientAdmin: boolean,
    private actions$: Actions,
    private adminUserHttp: AdminUserHttpService,
    private userFacade: UserNgrxFacade,
    private snackBar: MatSnackBar,

    // Dialogs
    private pwdResetDialog: EhsPasswordResetDialogService,
    private confirmDialog: EhsConfirmDialogService,
    private verifyEmailDialog: EhsVerifyEmailDialogService,
    private moveUserDialog: EhsMoveUserCompanyDialogService,
    private mergeUserDialog: EhsMergeUserDialogService,
    private editUserDialog: EhsEditUserDialogService,
    private restrictDataDialog: EhsAdminRestrictDataDialogService
  ) {}

  /**
   * This intercepts an action that could be dispatched
   * from the user-registration-ngrx.effects.ts file.
   */
  onListUserRegistrationsAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.listAsAdmin.success),
      mergeMap(({ payload: { entities } }) => {
        if (!entities?.length) {
          return [];
        }

        const users = entities
          .filter((userRegistration) =>
            isDbDocument(
              (userRegistration as UserRegistrationWithResultFlag).user
            )
          )
          .map(
            (userRegistration) =>
              (userRegistration as UserRegistrationWithResultFlag).user as User
          );

        if (!users.length) {
          return [];
        }

        return [userNgrxActions.setMany({ entities: users })];
      })
    )
  );

  createWalkin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.createWalkin.req),
      mergeMap(({ payload: { user, eventLocation, groupType, onsiteTypes } }) =>
        this.adminUserHttp
          .createWalkin({
            user,
            groupType,
            eventLocation,
            onsiteTypes,
            clientAdmin: this.clientAdmin
          })
          .pipe(
            tap(
              () =>
                this.snackBar.open('Successfully created a new walkin', 'Ok'),
              () => this.snackBar.open('Error creating a walkin', 'Ok')
            ),
            map((res) => userNgrxActions.createWalkin.success(res)),
            catchError((err: HttpErrorResponse) => [
              userNgrxActions.createWalkin.failed({ error: err.error })
            ])
          )
      )
    )
  );

  createAdditionalWalkin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.createAdditionalWalkin.req),
      mergeMap(
        ({
          payload: { user, eventLocation, groupType, onsiteTypes, isAdditional }
        }) =>
          this.adminUserHttp
            .createAdditionalWalkin({
              user,
              groupType,
              eventLocation,
              onsiteTypes,
              isAdditional,
              clientAdmin: this.clientAdmin
            })
            .pipe(
              tap(
                () =>
                  this.snackBar.open('Successfully created a new walkin', 'Ok'),
                () => this.snackBar.open('Error creating a walkin', 'Ok')
              ),
              map((res) => userNgrxActions.createAdditionalWalkin.success(res)),
              catchError((err: HttpErrorResponse) => [
                userNgrxActions.createAdditionalWalkin.failed({
                  error: err.error
                })
              ])
            )
      )
    )
  );

  onSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.list.req, userNgrxActions.loadMore),
      withLatestFrom(this.userFacade.pageNumber$),
      mergeMap(([{ payload }, pageNumber]) =>
        this.adminUserHttp
          .listUsers(
            { ...payload, pageNumber },
            { clientAdmin: this.clientAdmin }
          )
          .pipe(
            map(({ users, hasNext }) =>
              userNgrxActions.list.success({ users, hasNext })
            ),
            catchError(() => [userNgrxActions.list.failed()])
          )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.get.req),
      mergeMap(({ payload: { id } }) =>
        this.adminUserHttp
          .getUserById(id, { clientAdmin: this.clientAdmin })
          .pipe(
            map((entity) => userNgrxActions.get.success({ entity })),
            catchError(() => [userNgrxActions.get.failed()])
          )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.updateUser.req),
      mergeMap(({ payload: user }) =>
        this.adminUserHttp.updateUser(user as Partial<User>).pipe(
          tap(
            () => this.snackBar.open('User updated', 'Ok'),
            () =>
              this.snackBar.open('Oops, there was an error updating user', 'Ok')
          ),
          map((user) => userNgrxActions.updateUser.success({ entity: user })),
          catchError(() => [userNgrxActions.updateUser.failed()])
        )
      )
    )
  );

  getUserWithUnique$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.getUserWithUnique.req),
      mergeMap((action) =>
        this.adminUserHttp
          .getUserFromUnique(action.payload, { clientAdmin: this.clientAdmin })
          .pipe(
            map((user) =>
              userNgrxActions.getUserWithUnique.success({ entity: user })
            ),
            catchError((err: HttpErrorResponse) => [
              userNgrxActions.getUserWithUnique.failed(err.error)
            ])
          )
      )
    )
  );

  loginAsUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.loginAsUser.req),
      mergeMap(({ payload: { userId, url } }) =>
        this.adminUserHttp.loginAsUser(userId).pipe(
          tap(() => {
            // To route within the current tab:
            // this.router.navigate(['/'])
            // otherwise, after 2640, we now route into a new tab:
            window.open(url || '/', '_blank');
          }),
          tap(
            () => this.snackBar.open('Logging in as user...', 'Ok'),
            () =>
              this.snackBar.open(
                'Oops, there was an error logging in as user',
                'Ok'
              )
          ),
          mergeMap((user) => [userNgrxActions.loginAsUser.success({ user })]),
          catchError(({ error }: HttpErrorResponse) => [
            userNgrxActions.loginAsUser.failed({ error })
          ])
        )
      )
    )
  );

  passwordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.resetPassword.req),
      mergeMap(({ payload: { user } }) =>
        this.pwdResetDialog.open({ user }).pipe(
          take(1),
          mergeMap((pwd) => {
            if (!pwd || typeof pwd !== 'string') {
              return [userNgrxActions.resetPassword.canceled()];
            }

            return this.adminUserHttp
              .passwordReset({
                userId: getId(user),
                pwd
              })
              .pipe(
                tap(
                  () => this.snackBar.open('Reset password', 'Ok'),
                  () => this.snackBar.open('Oops, something went wrong', 'Ok')
                ),
                map(() => userNgrxActions.resetPassword.success()),
                catchError((err: HttpErrorResponse) => [
                  userNgrxActions.resetPassword.failed({ error: err.error })
                ])
              );
          })
        )
      )
    )
  );

  passwordResetEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.passwordResetEmail.req),
      mergeMap(({ payload: { user } }) =>
        this.adminUserHttp.passwordResetEmail({ user }).pipe(
          tap(
            () => this.snackBar.open(`Reset Email sent to ${user.email}`, 'Ok'),
            () => this.snackBar.open('Oops, there was an error', 'Ok')
          ),
          map(() => userNgrxActions.passwordResetEmail.success()),
          catchError(() => [userNgrxActions.passwordResetEmail.failed()])
        )
      )
    )
  );

  removeMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.removeMFA.req),
      mergeMap(({ payload: { user } }) =>
        this.confirmDialog
          .open({
            title: `Remove user ${user.firstName} ${user.lastName} MFA?`,
            message: `Are you sure you want to remove ALL MFA from user ${user.firstName} ${user.lastName}?`
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [userNgrxActions.removeMFA.canceled()];
              }

              return this.adminUserHttp
                .removeMFA({
                  userId: getId(user)
                })
                .pipe(
                  tap(
                    () => this.snackBar.open('MFA Removed', 'Ok'),
                    () => this.snackBar.open('Oops, something went wrong', 'Ok')
                  ),
                  map((user) =>
                    userNgrxActions.removeMFA.success({
                      user: { ...user, hasMFA: false }
                    })
                  ),
                  catchError((err: HttpErrorResponse) => [
                    userNgrxActions.removeMFA.failed({ error: err.error })
                  ])
                );
            })
          )
      )
    )
  );

  disableMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.disableMFA.req),
      mergeMap(({ payload: { user } }) =>
        this.confirmDialog
          .open({
            title: `Disable MFA for ${user.firstName} ${user.lastName}?`,
            message: `Are you sure you want to disable MFA for ${user.firstName} ${user.lastName}? They will not be prompted for MFA again.`
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [userNgrxActions.disableMFA.canceled()];
              }

              return this.adminUserHttp
                .disableMFA({
                  userId: getId(user)
                })
                .pipe(
                  tap(
                    () => this.snackBar.open('MFA Disabled', 'Ok'),
                    () => this.snackBar.open('Oops, something went wrong', 'Ok')
                  ),
                  map((user) =>
                    userNgrxActions.disableMFA.success({
                      user: { ...user, hasMFADisabled: true }
                    })
                  ),
                  catchError((err: HttpErrorResponse) => [
                    userNgrxActions.disableMFA.failed({ error: err.error })
                  ])
                );
            })
          )
      )
    )
  );

  enableMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.enableMFA.req),
      mergeMap(({ payload: { user } }) =>
        this.confirmDialog
          .open({
            title: `Enable MFA for ${user.firstName} ${user.lastName}?`,
            message: `Are you sure you want to enable MFA for ${user.firstName} ${user.lastName}? They will be prompted for MFA again.`
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [userNgrxActions.enableMFA.canceled()];
              }

              return this.adminUserHttp
                .enableMFA({
                  userId: getId(user)
                })
                .pipe(
                  tap(
                    () => this.snackBar.open('MFA Enabled', 'Ok'),
                    () => this.snackBar.open('Oops, something went wrong', 'Ok')
                  ),
                  map((user) =>
                    userNgrxActions.enableMFA.success({
                      user: { ...user, hasMFADisabled: undefined }
                    })
                  ),
                  catchError((err: HttpErrorResponse) => [
                    userNgrxActions.enableMFA.failed({ error: err.error })
                  ])
                );
            })
          )
      )
    )
  );

  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.verifyEmail.req),
      mergeMap(({ payload: { user } }) =>
        // Get Google User to determine if already verified
        this.adminUserHttp.getUserById(getId(user)).pipe(
          take(1),
          switchMap((user) => this.verifyEmailDialog.open({ user })),
          mergeMap((confirm) => {
            if (!confirm) {
              return [userNgrxActions.verifyEmail.canceled()];
            }

            return this.adminUserHttp.verifyEmail({ userId: getId(user) }).pipe(
              take(1),
              tap(
                () => this.snackBar.open('Verified Email', 'Ok'),
                () =>
                  this.snackBar.open(
                    'Oops, something went wrong with verifying email',
                    'Ok'
                  )
              ),
              map(() => userNgrxActions.verifyEmail.success()),
              catchError(({ error }: HttpErrorResponse) => [
                userNgrxActions.verifyEmail.failed({ error })
              ])
            );
          })
        )
      )
    )
  );

  unlockAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.unlockAccount.req),
      mergeMap(({ payload: { user } }) =>
        this.confirmDialog
          .open({
            title: `Unlock user ${user.firstName} ${user.lastName}`,
            message: `Are you sure you want to unlock ${user.firstName} ${user.lastName}?`
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [userNgrxActions.unlockAccount.canceled()];
              }

              return this.adminUserHttp
                .unlockAccount({ user: getId(user) })
                .pipe(
                  tap(
                    () => this.snackBar.open('Unlocked user account', 'Ok'),
                    () =>
                      this.snackBar.open(
                        'Oops, there was an unexpected error unlocking account',
                        'Ok'
                      )
                  ),
                  map((user) =>
                    userNgrxActions.unlockAccount.success({
                      user: { ...user, locked: false }
                    })
                  ),
                  catchError(({ error }: HttpErrorResponse) => [
                    userNgrxActions.unlockAccount.failed({ error })
                  ])
                );
            })
          )
      )
    )
  );

  moveUserCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.moveUserCompany.req),
      mergeMap(({ payload: { user } }) =>
        this.moveUserDialog.open({ user }).pipe(
          mergeMap((confirm) => {
            if (!confirm) {
              return [userNgrxActions.moveUserCompany.canceled()];
            }

            const { user, company } = confirm;

            return this.adminUserHttp
              .moveUserCompany({
                user: getId(user),
                company: getId(company)
              })
              .pipe(
                tap(
                  () => this.snackBar.open('User moved to new company', 'Ok'),
                  () =>
                    this.snackBar.open(
                      'Oops, something went wrong moving user',
                      'Ok'
                    )
                ),
                map((user) =>
                  userNgrxActions.moveUserCompany.success({ user })
                ),
                catchError(({ error }: HttpErrorResponse) => [
                  userNgrxActions.moveUserCompany.failed({ error })
                ])
              );
          })
        )
      )
    )
  );

  removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.removeUser.req),
      mergeMap(({ payload: { user } }) =>
        this.confirmDialog
          .open({
            title: `Flag ${user.firstName} ${user.lastName} for deletion`,
            message: `Are you sure you want to remove ${user.firstName} ${user.lastName} from the platform?`
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [userNgrxActions.removeUser.canceled()];
              }

              return this.adminUserHttp.removeUser({ user: getId(user) }).pipe(
                tap(
                  () => this.snackBar.open('User removed', 'Ok'),
                  () =>
                    this.snackBar.open(
                      'Oops, there was an error removing user',
                      'Ok'
                    )
                ),
                map((user) => userNgrxActions.removeUser.success({ user })),
                catchError(({ error }: HttpErrorResponse) => [
                  userNgrxActions.removeUser.failed({ error })
                ])
              );
            })
          )
      )
    )
  );

  flagRemoveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.flagRemoveUser.req),
      mergeMap(({ payload: { user } }) =>
        this.confirmDialog
          .open({
            title: `${user.flaggedForDeletion ? 'Un-flag' : 'Flag'} ${
              user.firstName
            }
            ${user.lastName} for deletion?`,
            message: user.flaggedForDeletion
              ? 'Are you sure you want to unflag the user?'
              : 'Are you sure you want to flag the user for deletion? An admin with removal permissions can then remove this user.'
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [userNgrxActions.flagRemoveUser.canceled()];
              }

              return this.adminUserHttp.flagRemoveUser({ user }).pipe(
                tap(
                  () => this.snackBar.open('User flagged for removal', 'Ok'),
                  () =>
                    this.snackBar.open(
                      'Oops, there was an error flagging user',
                      'Ok'
                    )
                ),
                map((user) => userNgrxActions.flagRemoveUser.success({ user })),
                catchError(({ error }: HttpErrorResponse) => [
                  userNgrxActions.flagRemoveUser.failed({ error })
                ])
              );
            })
          )
      )
    )
  );

  mergeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.mergeUsers.req),
      withLatestFrom(this.userFacade.selectedUsers$),
      mergeMap(([, [firstUser, secondUser]]) =>
        this.mergeUserDialog
          .open({
            usersToMerge: [firstUser, secondUser]
          })
          .pipe(
            filter((_) => !!_),
            mergeMap((res) => {
              if (!res) {
                return [userNgrxActions.mergeUsers.canceled()];
              }

              return this.adminUserHttp.mergeUser(res).pipe(
                tap(
                  () => this.snackBar.open('Users merged', 'Ok'),
                  () =>
                    this.snackBar.open(
                      'Oops, there was an error merging users',
                      'Ok'
                    )
                ),
                map((user) =>
                  userNgrxActions.mergeUsers.success({
                    mergedUser: user,
                    userRemoved: res.mergeOrphanedUser
                  })
                ),
                catchError(() => [userNgrxActions.mergeUsers.failed()])
              );
            })
          )
      )
    )
  );

  editUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.editUser.req),
      mergeMap((action) =>
        this.editUserDialog
          .open({
            user: action.payload.user
          })
          .pipe(
            take(1),
            mergeMap((res) => {
              if (!res) {
                return [userNgrxActions.editUser.canceled()];
              }

              return this.adminUserHttp.updateUser(res.user).pipe(
                tap(
                  () => this.snackBar.open('User updated', 'Ok'),
                  () =>
                    this.snackBar.open(
                      'Oops, there was an error updating user',
                      'Ok'
                    )
                ),
                map((user) => userNgrxActions.editUser.success({ user })),
                catchError(({ error }: HttpErrorResponse) => [
                  userNgrxActions.editUser.failed({
                    error
                  })
                ])
              );
            })
          )
      )
    )
  );

  restrictUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.restrictUserData.req),
      mergeMap((action) =>
        this.restrictDataDialog
          .open({
            user: action.payload.user
          })
          .pipe(
            take(1),
            mergeMap((res) => {
              if (!res) {
                return [userNgrxActions.restrictUserData.canceled()];
              }

              return this.adminUserHttp.restrictUserData(res).pipe(
                tap(
                  () => this.snackBar.open('User updated', 'Ok'),
                  () =>
                    this.snackBar.open(
                      'Oops, there was an error updating user',
                      'Ok'
                    )
                ),
                map((user) =>
                  userNgrxActions.restrictUserData.success({ user })
                ),
                catchError(({ error }: HttpErrorResponse) => [
                  userNgrxActions.restrictUserData.failed({ error })
                ])
              );
            })
          )
      )
    )
  );

  promoteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.promoteUser.req),
      mergeMap(({ payload: { user } }) =>
        this.adminUserHttp.promoteUser(user).pipe(
          tap(
            () => this.snackBar.open('User updated', 'Ok'),
            () =>
              this.snackBar.open('Oops, there was an error updating user', 'Ok')
          ),
          tap((user) => console.log({ user })),
          map((user) => userNgrxActions.promoteUser.success({ user })),
          catchError(() => [userNgrxActions.promoteUser.failed()])
        )
      )
    )
  );

  demoteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.demoteUser.req),
      mergeMap(({ payload: { user } }) =>
        this.adminUserHttp.demoteUser(user).pipe(
          tap(
            () => this.snackBar.open('User updated', 'Ok'),
            () =>
              this.snackBar.open('Oops, there was an error updating user', 'Ok')
          ),
          map((user) => userNgrxActions.demoteUser.success({ user })),
          catchError(() => [userNgrxActions.demoteUser.failed()])
        )
      )
    )
  );

  desyncUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.desyncUser.req),
      mergeMap(({ payload: { user } }) =>
        this.adminUserHttp.desyncUser({ user }).pipe(
          tap(
            () => this.snackBar.open('User updated', 'Ok'),
            () =>
              this.snackBar.open('Oops, there was an error updating user', 'Ok')
          ),
          map((user) => userNgrxActions.desyncUser.success({ user })),
          catchError(() => [userNgrxActions.desyncUser.failed()])
        )
      )
    )
  );

  createPartialUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userNgrxActions.createPartialUser.req),
      mergeMap(({ payload: { user } }) =>
        this.adminUserHttp
          .createPartialUser({ user, clientAdmin: this.clientAdmin })
          .pipe(
            tap(
              () => this.snackBar.open('Created User', 'Ok'),
              () =>
                this.snackBar.open(
                  'Oops, there was an error creating user',
                  'Ok'
                )
            ),
            map((user) => userNgrxActions.createPartialUser.success({ user })),
            catchError(() => [userNgrxActions.createPartialUser.failed()])
          )
      )
    )
  );
}
