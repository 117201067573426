<div>
  <img id="image"
       height="600"
       [src]="sanitizedUrl">
  <div class="crop-actions">
    <div>
      <button mat-icon-button
              title="Crop Mode"
              [ngClass]="{'icon-opacity ': cropperMode !== 'crop'}"
              (click)="toCropMode()">
        <mat-icon>crop</mat-icon>
      </button>
      <button mat-icon-button
              title="Drag Mode"
              [ngClass]="{'icon-opacity ': cropperMode !== 'drag'}"
              (click)="toDragMode()">
        <mat-icon>open_with</mat-icon>
      </button>
    </div>
    <div>
      <button style="margin-right: 1rem"
              mat-flat-button
              (click)="reset()">
        Reset
      </button>
      <button color="primary"
              mat-flat-button
              (click)="crop()">
        Ok
      </button>
    </div>

  </div>
</div>
