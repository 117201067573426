import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EhsEditTeamDialogComponent } from './ehs-edit-team-dialog.component';
import { EhsEditTeamDialogData } from './ehs-edit-team-dialog.data';
import { HealthProgramTeam } from '@common';

@Injectable({ providedIn: 'root' })
export class EhsEditTeamDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(
    data: EhsEditTeamDialogData
  ): Observable<Partial<HealthProgramTeam>> {
    return this.matDialog
      .open(EhsEditTeamDialogComponent, {
        data,
        width: '450px',
        disableClose: true
      })
      .afterClosed();
  }
}
