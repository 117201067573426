import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ehs-call-to-action',
  templateUrl: './ehs-call-to-action.component.html',
  styleUrls: ['./ehs-call-to-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsCallToActionComponent {
  /**
   * The message to display in the call to action.
   */
  @Input() message?: string;
  /**
   * The action message to display in the call to action.
   */
  @Input() actionMessage?: string;
  /**
   * If passed, the button turns into a link instead
   */
  @Input() actionLink?: string;
  /**
   * The icon to display at the top.
   */
  @Input() icon?: string;
  /**
   * Color of the action button, defaults to "primary"
   */
  @Input() actionColor: 'primary' | 'accent' | 'warn' = 'primary';
  /**
   * The action to display in the call to action.
   */
  @Output() action = new EventEmitter<void>();
}
