import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IconImageResizeData } from './icon-image-resize-dialog-data';
import { IconImageResizeDialogComponent } from './icon-image-resize-dialog.component';

@Injectable({ providedIn: 'root' })
export class IconImageResizeService {
  constructor(private matDialog: MatDialog) {}

  public open(data: IconImageResizeData): Observable<any> {
    return this.matDialog
      .open(IconImageResizeDialogComponent, {
        data,
        maxWidth: '80vw',
        maxHeight: '80vh'
      })
      .afterClosed();
  }
}
