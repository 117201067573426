<h1 mat-dialog-title>Edit Team</h1>
<form #addAdminForm="ngForm"
      novalidate
      (submit)="submit(addAdminForm)">
  <mat-dialog-content>
    <div fxLayout="column"
         fxLayoutGap="8px">
      <ng-container *ngIf="selectedIcon$ | async as teamIcon">
        <div fxLayout="row"
             fxLayoutAlign="center center">
          <img [src]="getTeamIconSrc(teamIcon)"
               height="50px"
               width="50px">
        </div>
        <button mat-flat-button
                color="primary"
                type="button"
                (click)="openTeamIconSelectDialog()">
          Select your team icon
        </button>
      </ng-container>
      <mat-form-field>
        <mat-label>Team Name</mat-label>
        <input matInput
               #nameField="ngModel"
               [(ngModel)]="teamName"
               name="teamName"
               required>
        <mat-error *ngIf="nameField.hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>

    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button
            type="button"
            [mat-dialog-close]="null">
      Cancel</button>
    <button mat-flat-button
            color="primary"
            cdkFocusInitial>
      Confirm
    </button>
  </mat-dialog-actions>
</form>