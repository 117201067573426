import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HealthProgramTeam } from '@common';
import { QueryListService } from '../../util/query-list.service';

const BASE_URL = '/api/v1/health-program-team';
const ADMIN_URL = '/api/v1/admin/health-program-team';

@Injectable({
  providedIn: 'root'
})
export class AdminHealthProgramTeamHttpService {
  constructor(
    private http: HttpClient,
    private queryList: QueryListService
  ) {}

  public list(params: { healthProgram: string }) {
    const { healthProgram } = params;

    return this.http.get<{ healthProgramTeams: HealthProgramTeam[] }>(
      `${ADMIN_URL}/list/program?healthProgram=${healthProgram}`
    );
  }

  public delete(params: { healthProgramTeam: string }) {
    const { healthProgramTeam } = params;

    return this.http.delete<{ deletedTeam: HealthProgramTeam }>(
      `${ADMIN_URL}/delete?healthProgramTeam=${healthProgramTeam}`
    );
  }

  public update(params: { healthProgramTeam: Partial<HealthProgramTeam> }) {
    const { healthProgramTeam } = params;

    return this.http.post<{ updatedTeam: HealthProgramTeam }>(
      `${ADMIN_URL}/update`,
      healthProgramTeam
    );
  }

  public create(params: { healthProgramTeam: Partial<HealthProgramTeam> }) {
    const { healthProgramTeam } = params;

    return this.http.post<{ createdTeam: HealthProgramTeam }>(
      `${ADMIN_URL}/create`,
      healthProgramTeam
    );
  }
}
