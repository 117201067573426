import { NgModule } from '@angular/core';
import { EhsButtonAnimationDirective } from './button-hover-animation.directive';
import { EhsElevateCardAnimationDirective } from './elevate-card-animation.directive';
import { EhsFadeInAnimationDirective } from './fade-in-animation.directive';
import { EhsFillProgressBarAnimationDirective } from './fill-progress-bar-animation.directive';
import { EhsNumberIncrementAnimationDirective } from './number-increment-animation.directive';
import { EhsBorderExpandAnimationDirective } from './border-expand-animation.directive';

@NgModule({
  declarations: [
    EhsButtonAnimationDirective,
    EhsElevateCardAnimationDirective,
    EhsFadeInAnimationDirective,
    EhsFillProgressBarAnimationDirective,
    EhsNumberIncrementAnimationDirective,
    EhsBorderExpandAnimationDirective
  ],
  exports: [
    EhsButtonAnimationDirective,
    EhsElevateCardAnimationDirective,
    EhsFadeInAnimationDirective,
    EhsFillProgressBarAnimationDirective,
    EhsNumberIncrementAnimationDirective,
    EhsBorderExpandAnimationDirective
  ]
})
export class EhsAnimationsModule {}
