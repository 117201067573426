import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Icons } from '@common';
import { IconSelectDialogData } from './icon-select-dialog-data';
import { IconSelectDialogComponent } from './icon-select-dialog.component';

@Injectable({ providedIn: 'root' })
export class IconSelectDialogService {
  constructor(private matDialog: MatDialog) {}

  public open(data: IconSelectDialogData): Observable<{
    icon: Icons;
    profileImage?: string;
  }> {
    return this.matDialog
      .open(IconSelectDialogComponent, {
        data,
        maxWidth: '450px'
      })
      .afterClosed();
  }
}
