import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdminUserSearchForm,
  CommonResponse,
  OnsiteRegistrationType,
  OnsiteUserRegistration,
  PartialUser,
  User,
  UserWithRegistrations,
  UserRegistration,
  UserResult,
  UserWithVerified
} from '@common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminUserHttpService {
  private baseUrl = 'api/v1/admin';
  private clientAdminUrl = 'api/v1/client';

  constructor(private http: HttpClient) {}

  /**
   * Logs the admin in as another user within the app
   */
  public loginAsUser(user: string): Observable<User> {
    return this.http.put<User>('api/v1/admin/user/login', { user });
  }

  /**
   * Log out of the user account
   */
  public logout(): Observable<CommonResponse> {
    return this.http.put<CommonResponse>('api/v1/admin/user/logout', {});
  }

  /**
   * Returns the list of user results for the given user
   */
  public listUserResults(params: { user: string }): Observable<UserResult[]> {
    const { user } = params;

    return this.http.get<UserResult[]>('api/v1/admin/user/result', {
      params: new HttpParams().append('user', user)
    });
  }

  /**
   * List the user-registrations
   * @deprecated
   */
  public listUserRegistrations(params: {
    user: string;
    /**
     * If we are to populate the eventLocation
     * of this user-registration.
     */
    eventLocation?: boolean;
  }): Observable<UserRegistration[]> {
    const { user, eventLocation } = params;

    return this.http.get<UserRegistration[]>('api/v1/admin/user/registration', {
      params: new HttpParams()
        .append('user', user)
        .append('eventLocation', '' + !!eventLocation)
    });
  }

  /**
   * Gets user by id
   */
  public getUserById(
    userId: string,
    params?: { clientAdmin: boolean }
  ): Observable<UserWithVerified> {
    return this.http.get<UserWithVerified>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/user/${userId}`
    );
  }

  /**
   * Gets user by id
   */
  public getUserByUsername(username: string): Observable<User> {
    return this.http.get<User>(`api/v1/admin/username/${username}`);
  }

  /**
   * Gets a user based on their unique fields
   */
  public getUserFromUnique(
    user: Pick<User, 'lastName' | 'birthDay' | 'ssn'>,
    params?: { clientAdmin: boolean }
  ) {
    const { lastName, birthDay, ssn } = user;

    return this.http.get<User>(
      `${params?.clientAdmin ? this.clientAdminUrl : this.baseUrl}/user/unique`,
      {
        // There might be a better way to do this
        params: new HttpParams({
          fromObject: {
            lastName,
            birthDay,
            ssn
          }
        })
      }
    );
  }

  /**
   * Resets the given user's password
   */
  public passwordReset(params: {
    userId: string;
    pwd: string;
  }): Observable<User> {
    return this.http.put<User>('api/v1/admin/password/reset', params);
  }

  /**
   * Send password reset email to the given user
   */
  public passwordResetEmail(params: { user: User }): Observable<User> {
    return this.http.put<User>('api/v1/admin/password/reset-email', params);
  }

  /**
   * Removes all MFA's from the given user
   */
  public removeMFA(params: { userId: string }): Observable<User> {
    return this.http.put<User>('api/v1/admin/mfa/remove', params);
  }

  /**
   * Disable MFA from the given user
   */
  public disableMFA(params: { userId: string }): Observable<User> {
    return this.http.put<User>('api/v1/admin/mfa/disable', params);
  }

  /**
   * Enable MFA from the given user
   */
  public enableMFA(params: { userId: string }): Observable<User> {
    return this.http.put<User>('api/v1/admin/mfa/enable', params);
  }

  /**
   * Creates a walk-in for the given user
   */
  public createWalkin(params: {
    user: PartialUser;
    eventLocation: string;
    groupType?: number;
    onsiteTypes: OnsiteRegistrationType[];
    clientAdmin?: boolean;
  }): Observable<{
    user: User;
    userRegistration: OnsiteUserRegistration;
  }> {
    return this.http.post<{
      user: User;
      userRegistration: OnsiteUserRegistration;
    }>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/walkin/create`,
      params
    );
  }

  /**
   * Creates an additional registration walk-in for the given user
   */
  public createAdditionalWalkin(params: {
    user: PartialUser;
    eventLocation: string;
    groupType?: number;
    onsiteTypes: OnsiteRegistrationType[];
    isAdditional?: boolean;
    clientAdmin?: boolean;
  }): Observable<{
    user: User;
    userRegistration: OnsiteUserRegistration;
  }> {
    return this.http.post<{
      user: User;
      userRegistration: OnsiteUserRegistration;
    }>(
      `${
        params?.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/additional/walkin/create`,
      params
    );
  }

  /**
   * Verify the given user
   */
  public verifyEmail(params: { userId: string }): Observable<User> {
    return this.http.put<User>('api/v1/admin/verify/email', params);
  }

  /**
   * Update the user's information
   */
  public updateUser(user: Partial<User>): Observable<User> {
    return this.http.put<User>('api/v1/admin/user/update', user);
  }

  /**
   * Exclude user results and responses from exports including:
   * - HRA exports
   * - Incentive exports
   * - Marquee exports
   * - LookerStudio exports
   */
  public restrictUserData(user: Partial<User>): Observable<User> {
    return this.http.put<User>('api/v1/admin/user/restrict', user);
  }

  /**
   * Promote the user to client-admin status
   */
  public promoteUser(user: Partial<User>): Observable<User> {
    return this.http.put<User>('api/v1/admin/user/promote', user);
  }

  /**
   * Remote client-admin status from user
   */
  public demoteUser(user: Partial<User>): Observable<User> {
    return this.http.put<User>('api/v1/admin/user/demote', user);
  }

  /**
   * Returns the list of users based
   * on the query
   */
  public listUsers(
    search: AdminUserSearchForm,
    params?: { clientAdmin: boolean }
  ): Observable<{ users: (User | UserWithRegistrations)[]; hasNext: boolean }> {
    return this.http.get<{ users: User[]; hasNext: boolean }>(
      `${params?.clientAdmin ? this.clientAdminUrl : this.baseUrl}/user/list`,
      {
        // Only send the params that are not undefined
        params: Object.entries(search || {}).reduce(
          (_params, [key, value]) =>
            value ? _params.append(key, '' + value) : _params,
          new HttpParams()
        )
      }
    );
  }

  /**
   * Exports the users for the given company
   */
  public exportUsers(params: {
    company: string;
    clientAdmin?: boolean;
  }): Observable<CommonResponse> {
    const { company } = params;

    return this.http.post<CommonResponse>(
      `${
        params.clientAdmin ? this.clientAdminUrl : this.baseUrl
      }/company/user/export`,
      {
        company
      }
    );
  }

  /**
   * The userId to unlock the account
   */
  public unlockAccount(params: { user: string }): Observable<User> {
    const { user } = params;

    return this.http.post<User>('api/v1/admin/user/unlock', { user });
  }

  /**
   * Flags the user for deletion,
   * change its status with the flaggedForDeletion property
   */
  public flagRemoveUser(params: { user: User }): Observable<User> {
    const { user } = params;
    const { _id, flaggedForDeletion } = user;

    return this.http.post<User>('api/v1/admin/user/flag', {
      _id,
      flaggedForDeletion
    });
  }

  /**
   * Removes the user from the platform
   */
  public removeUser(params: { user: string }): Observable<User> {
    const { user } = params;

    return this.http.delete<User>(`api/v1/admin/user/${user}`);
  }

  public mergeUser(params: {
    mergePrimaryUser: User | PartialUser;
    mergeOrphanedUser: User | PartialUser;
  }): Observable<User> {
    const { mergePrimaryUser, mergeOrphanedUser } = params;

    return this.http.post<User>('/api/v1/admin/user/merge', {
      mergePrimaryUser,
      mergeOrphanedUser
    });
  }

  public moveUserCompany(params: {
    user: string;
    company: string;
  }): Observable<User> {
    const { user, company } = params;

    return this.http.post<User>('/api/v1/admin/user/company', {
      user,
      company
    });
  }

  /**
   * Removes the previous company references from the user.
   */
  public desyncUser(params: { user: string }): Observable<User> {
    const { user } = params;

    return this.http.post<User>('/api/v1/admin/user/desync', {
      user
    });
  }

  public createPartialUser(params: {
    user: Partial<PartialUser>;
    clientAdmin?: boolean;
  }): Observable<PartialUser> {
    const { user, clientAdmin } = params;

    console.log('create partial user - ', params);

    return this.http.post<PartialUser>(
      `${clientAdmin ? this.clientAdminUrl : this.baseUrl}/user/create`,
      user
    );
  }
}
