export const environment = {
  production: true,
  environment: 'test',

  // Feature flags
  showFaxDetails: true,
  showProfileIcons: true,
  showWalkingProgram: true,
  showAnimations: true,
  showEditRegistration: true,
  showIncentiveTiers: true,
  showPerksResource: true,
  disableMetabolicSyndrome: false,
  disableAddedReg: false,
  hraDebug: true,
  deleteHra: true,
  showReports: false,
  showUserRegCancel: false,
  showSquare: false,
  gcfGWAppsSync: true,
  // Show the Field Report Language on the User Profile
  showUserLanguage: true,
  showNotifications: true,
  showUploadForm: true,
  showReimbursements: true,
  showCustomReportForm: true,
  mobileAppStore: true,
  showInstallButton: true,

  /** Disable the microsoft login button */
  showMicrosoftLogin: true,

  /** Show force company MFA */
  showForceMFA: true,

  /**Allow double onsite registration */
  allowDoubleOnsite: false,

  // Configuration
  firebaseApiKey: 'AIzaSyAk46_3jjwWc8MHlWEfn0mqmso1AD77C88',
  firebaseAuthDomain: 'ct-ehs-customer-portal.firebaseapp.com',
  reCaptchaKey: '6LdjR9wUAAAAADmUVFrvcif3L60ycX5qfJTBvkMD',
  baseLogoUrl: 'https://storage.googleapis.com/ct-ehs-customer-logos',
  baseArticleImagesUrl:
    'https://storage.googleapis.com/ct-ehs-customer-articles',
  baseUrl: 'https://demo.empower.health/',

  // Panel settings: https://analytics.google.com/analytics/web/#/report-home/a167150610w233287021p218951615
  googleAnalyticsId: 'UA-167150610-1',
  pingdom: 'pa-5f8da24edfb2d5001300008c.js',
  useCookiePolicy: true,
  cookieKey: '4d72e2a6-d9c2-448c-80f4-4afaa5c74023-test',
  /** If true, we download the requisition from GCS otherwise we download it from userRegistration.requisition */
  useGcsReqs: true,
  mapsApiKey: 'AIzaSyAH-_G0D9KegDNkXDJm4b0lRKskRm8xfoE',
  walkingProgramMapId: '135f878d0fed950e',
  deepLinkRedirect: 'https://empowerhealthservices.com/applink/login',
  showAppLink: true
};
