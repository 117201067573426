import { ObjectId } from 'mongodb';
import { TestCategories } from '../../constants/test-categories';
import { Article } from '../article';
import { DbDocument } from '../db-document';
import { ServiceLabType } from '../event-service/service-lab-type';
import { UserTestGraphType } from './user-test-graph-type';
import { UserTestRange } from './user-test-range';
import { UserTestStatus } from './user-test-status';
import { UserTestType } from './user-test-type';
import { UserTestRisk } from './user-test-risk';

// Export enum UserTestStatus {}
/**
 * The user-id type, branded to prevent invalid reference usage.
 */
export type UserTestId = string & { readonly brand: unique symbol };
// tslint:disable-next-line: variable-name
export const UserTestId = (userTestId: string) => userTestId as UserTestId;

export type PrimaryOrderCode = string & { readonly brand: unique symbol };
// tslint:disable-next-line: variable-name
export const PrimaryOrderCode = (primaryOrderCode: string) =>
  primaryOrderCode as PrimaryOrderCode;

/**
 * **Note** this is the same as PrimaryOrderCode
 */
export type OrderCode = PrimaryOrderCode;
// tslint:disable-next-line: variable-name
export const OrderCode = (orderCode: string) => orderCode as OrderCode;

export type LabResultCode = string & { readonly brand: unique symbol };
// tslint:disable-next-line: variable-name
export const LabResultCode = (labResultCode: string) =>
  labResultCode as LabResultCode;

export type LoincCode = string & { readonly brand: unique symbol };
// tslint:disable-next-line: variable-name
export const LoincCode = (loincCode: string) => loincCode as LoincCode;

/**
 * A user test are tests available as additional services
 * and optional tests within event-services.
 * TODO: Add UserPanel version that is a special kind of
 * UserTest.
 */
export interface UserTest extends DbDocument<UserTestId> {
  /**
   * The name of the test. This is used to show the user the test
   * name.
   */
  name: string;
  /**
   * This is the technical name. This is only used if the `name` is not there.
   * This is usually the name given to a test by the Labs. This name will be returned
   * by the Lifepoint API for the tests.
   */
  technicalName: string;
  /**
   * The Measurement the test is in.
   * This is mapped from a dropdown to a raw string value.
   * TODO: Could be made an enum!
   */
  measurementUnit: string;
  /**
   * This just lists what Panel a test is part of.
   * We might need to display the tests that fall
   * into a Panel later in the future so let's move
   * that as well but for now we don't need to use it.
   *
   * Panels are "Special" kinds of user-tests that are passed
   * to life-point when creating an order. These will NOT be
   * returned by life-point
   *
   * Each value is just the code itself
   */
  panelNumbers: PrimaryOrderCode[];
  /**
   * The type of tests, most tests are "non-panels",
   * some are special kinds called "panels"
   */
  testType: UserTestType;
  /**
   * The USD cost of this test
   */
  cost: number;
  /**
   * The type of lab this test goes for.
   * Could be an empty string.
   */
  labType: ServiceLabType | '';
  /**
   * Code sent to Life-point.
   * Even the Type Panel will have the Panel code in the primaryOrderCode.
   *
   * **note** this isn't unique, and shouldn't used for lookups. The `getCode`
   * method might be deprecated with this in the future depending on the user-case.
   */
  primaryOrderCode: PrimaryOrderCode;
  /**
   * This is a backup order code. In case primaryOrderCode
   * is not specified, then the orderCode is the one sent to life-point.
   *
   * Generally this is almost always the same as primaryOrderCode, so
   * OrderCode shouldn't be directly use much.
   *
   * **note** I'm unsure how reliably unique this value, after learning
   * the primaryOrderCode is not unique.
   */
  orderCode: OrderCode;
  /**
   * Will be used to match with the userResultTestResult properties
   * resultCode property.
   */
  labResultCode: LabResultCode;
  /**
   * Not sure if this will be used in our app, but map it in case we need it later.
   */
  cptCode: string;
  /**
   * The category of the test, set as
   * an enum rather than code and value
   *
   * The category that will be displayed in the Lab Results Report Page.
   *  You will group the test results by this category.
   */
  category: TestCategories;
  /**
   * Used in optional Test Cards AND the "What is {test_name} in Lab Results Page
   * used to be description
   */
  shortDefinition: string;
  /**
   * Spanish version of shortDefinition
   */
  shortDefinitionEs: string;
  /**
   * Used in "Learn More" in "What is {test_name}" if the user clicks on it.
   * Refer to issue https://github.com/citent/ehs-customer-portal/issues/964
   * for more details
   */
  testDefinition: string;
  /**
   * Spanish version of textDefinition
   */
  testDefinitionEs: string;
  /**
   * Shown only if the user has Low risk (issue #964)
   */
  lowRiskDefinition: string;
  /**
   * Spanish version of lowRiskDefinition
   */
  lowRiskDefinitionEs: string;
  /**
   * Show only if the user has normal risk, issue #2107
   */
  inRangeDefinition: string;
  /**
   * Spanish version of inRangeDefinition
   */
  inRangeDefinitionEs: string;
  /**
   * Show only if user has High risk (issue #964)
   */
  highRiskDefinition: string;
  /**
   * Spanish version of highRiskDefinition
   */
  highRiskDefinitionEs: string;
  /**
   * Used to show the More Information by calling an
   * API to LOINC government resource. TBD
   */
  loincCode: LoincCode;
  /**
   * This code is used by the EHS team as reference. We **do not** handle validation
   * for these values at this time, its only a display key for them.
   */
  ehsKeyCode: string;
  /**
   * The list of ranges for this test. In GWApps, these are saved and
   * added/updated from their own endpoint/parsing/mapping, but saved
   * within the same user-test in EHS so its easier to find and use.
   */
  ranges: Array<UserTestRange>;
  /**
   * The list of health risks associated with this test.
   */
  risks: Array<UserTestRisk>;
  /**
   * The graph type determines what kid of input and
   * graph is shown.
   */
  graphType: UserTestGraphType;
  /**
   * Array of article references
   */
  articles: Array<string | ObjectId | Article>;
  /**
   * Additional Tube required
   */
  addtlTube?: boolean;
  /**
   * The status of the test. Used to hide/show the test to the user in reports
   * and the UI.
   *
   * @see isUserTestActive for using this flag correctly.
   */
  status: UserTestStatus;

  /**
   * Number of days the result need to be returned,if not returned in this time, the result will be marked as Not resulted
   */
  returnDeadline?: number;

  /**
   * Alternative user test which can be used as a replacement for this test.
   */
  alternativeTest?: string | ObjectId | UserTest;
}
