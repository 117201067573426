import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HealthProgram, HealthProgramTeam } from '@common';
import {
  EhsServiceTeamsListActionColumn,
  EhsServiceTeamsListActionType
} from './ehs-service-teams-list-table-column';
import { TeamIcons, TeamIconsSrcs } from '@common';

@Component({
  selector: 'ehs-service-teams-list',
  templateUrl: './ehs-service-teams-list.component.html',
  styleUrls: ['./ehs-service-teams-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EhsServiceTeamsListComponent {
  @Input() healthProgram?: HealthProgram;
  @Input() teams: HealthProgramTeam[] = [];
  @Input() loading: boolean;

  @Output() actionChange = new EventEmitter<{
    actionType: EhsServiceTeamsListActionType;
    healthProgramTeam: HealthProgramTeam;
  }>();

  public displayedColumns = [
    'team_icon',
    'team_name',
    'current_members',
    'max_members',
    'actions'
  ];

  public getTeamIconSrc(team: HealthProgramTeam) {
    const teamIcon = team?.teamIcon;

    if (!teamIcon) {
      return TeamIconsSrcs[TeamIcons.TEAM];
    }

    return TeamIconsSrcs[teamIcon];
  }

  public actions = [
    {
      type: 'view',
      name: 'View Raw Data'
    },
    {
      type: 'delete',
      name: 'Delete Team'
    },
    {
      type: 'edit',
      name: 'Edit Team'
    }
  ] as Array<EhsServiceTeamsListActionColumn>;

  public trackByFn(index: number) {
    return index;
  }

  public company = this.route?.snapshot?.params?.companyId;
  public eventService = this.route?.snapshot?.params?.eventServiceId;

  constructor(private route: ActivatedRoute) {}
}
