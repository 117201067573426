export enum EhsReportPdfImages {
  COVER_PAGE = '1-cover-page.jpg',
  BLOOD_PRESSURE_BMI_PAGE = '2-bp-and-bmi-page.jpg',
  UNDERSTANDING_YOUR_REPORT_PAGE = '3-overview-and-how-to-read-page.jpg',
  FIRST_RESULT_PAGE = '4a-first-result-page.jpg',
  SECOND_RESULT_PAGE = '4b-secondary-result-page.jpg',
  PREVIOUS_RESULTS_PAGE = '4c-previous-result-page.jpg',
  CROSSWORD_PAGE = '5-crossword-page.jpg',
  TIPS_TO_SUCCEED_PAGE = '6-tips-to-succeed.jpg',
  EXERCISE_SLEEP_REST_PAGE = '7-exercise-sleep-and-rest.jpg',
  EARLY_DETECTION_IS_KEY = '9-early-detection-is-key.jpg',
  DOCTOR_VISIT_PAGE = '9b-doctor-visit.jpg',
  PARTICIPATION_PAGE = '9c-participation-page.jpg',
  FEMALE_HEALTH_CHECKLIST_PAGE = '10-female-health-checklist.jpg',
  MALE_HEALTH_CHECKLIST_PAGE = '10-male-health-checklist.jpg'
}

export const EHS_REPORT_PDF_IMAGES = [
  EhsReportPdfImages.COVER_PAGE,
  EhsReportPdfImages.BLOOD_PRESSURE_BMI_PAGE,
  EhsReportPdfImages.UNDERSTANDING_YOUR_REPORT_PAGE,
  EhsReportPdfImages.FIRST_RESULT_PAGE,
  EhsReportPdfImages.SECOND_RESULT_PAGE,
  EhsReportPdfImages.PREVIOUS_RESULTS_PAGE,
  EhsReportPdfImages.CROSSWORD_PAGE,
  EhsReportPdfImages.TIPS_TO_SUCCEED_PAGE,
  EhsReportPdfImages.EXERCISE_SLEEP_REST_PAGE,
  EhsReportPdfImages.EARLY_DETECTION_IS_KEY,
  EhsReportPdfImages.DOCTOR_VISIT_PAGE,
  EhsReportPdfImages.PARTICIPATION_PAGE,
  EhsReportPdfImages.FEMALE_HEALTH_CHECKLIST_PAGE,
  EhsReportPdfImages.MALE_HEALTH_CHECKLIST_PAGE
];

export const EHS_REPORT_IMAGE_KEYS: (keyof GenerateResultsReportImagesConfig)[] =
  [
    'coverPage',
    'bpAndBmiPage',
    'overviewAndHowToReadPage',
    'firstResultPage',
    'secondaryResultPage',
    'previousResultPage',
    'crosswordPage',
    'tipsToSucceed',
    'exerciseSleepAndRest',
    'doctorVisit',
    'participationPage',
    'earlyDetectionIsKey',
    'femaleHealthChecklist',
    'maleHealthChecklist'
  ];

/**
 * Utility that takes the base page-name, and inserts `-es` before the `.jpg`.
 * This isn't the most optimal, but will work for now.
 *
 * **note** this only works if the names all end with `.jpg` or `.jpeg`!
 */
export const getSpanishFileName = (filename: string) => {
  if (filename.includes('.jpeg')) {
    return filename.replace('.jpeg', '-es.jpeg');
  }

  if (filename.includes('.jpg')) {
    return filename.replace('.jpg', '-es.jpg');
  }

  // Otherwise we don't know the extension, so skip.
  return filename;
};

/**
 * Map of image paths to render in the pdf.
 *
 * **note** I'm not sure what the path example will be, might change
 * depending on the environment.
 */
export interface GenerateResultsReportImagesConfig {
  /**
   * The cover-page image, or "page 1" in the former setup. Isn't dynamic
   */
  coverPage: string;
  /**
   * The blood-pressure and BMI page. This also has some member information.
   * Was "page 2", and is dynamic
   */
  bpAndBmiPage: string;
  /**
   * The overview and how-to-read page. Was "page 3" and is dynamic,
   * with the user's name at the top being dynamic.
   */
  overviewAndHowToReadPage: string;
  /**
   * The first result page, or "page 4a", starts rendering the results.
   * This page is also the only one rendered/sent to the doctor if
   * "resultsOnly" is passed to the function, along with the "secondaryResultPages"
   */
  firstResultPage: string;
  /**
   * The second result pages are added as needed for the remaining tests results.
   */
  secondaryResultPage: string;
  /**
   * The previous results page are added as needed for previous test results.
   */
  previousResultPage: string;
  /**
   * The cross word page is optionally added after the secondary result pages (if there are any)
   * and before page 5.
   */
  crosswordPage: string;
  /**
   * The page of tips for the end-user, shows tips to succeed.
   * Was page 6
   */
  tipsToSucceed: string;
  /**
   * This page shows 3 tips on how to succeed.
   * Was Page 7
   */
  exerciseSleepAndRest: string;
  /**
   * Tips for making most of Doctor's visit (9b-doctor-visit.jpeg)
   * Fluff Page
   */
  doctorVisit: string;
  /**
   * Participation Page (9c-participation-page.jpeg)
   * Fluff Page
   */
  participationPage: string;
  /**
   * This page shows some fields the user can fill out to help the doctor.
   * Was page 8
   *
   * This page shows a "early-detection-is-key" image.
   * was page 8, then changed from "stayHealthQuestionnaire"
   */
  earlyDetectionIsKey: string;
  /**
   * Checklist for females
   */
  femaleHealthChecklist: string;
  /**
   * Checklist for males
   */
  maleHealthChecklist: string;
}

export const convertEhsReportImagesToObject = ([
  coverPage,
  bpAndBmiPage,
  overviewAndHowToReadPage,
  firstResultPage,
  secondaryResultPage,
  previousResultPage,
  crosswordPage,
  tipsToSucceed,
  exerciseSleepAndRest,
  earlyDetectionIsKey,
  doctorVisit,
  participationPage,
  femaleHealthChecklist,
  maleHealthChecklist
]: string[]) =>
  ({
    coverPage,
    bpAndBmiPage,
    overviewAndHowToReadPage,
    firstResultPage,
    secondaryResultPage,
    previousResultPage,
    crosswordPage,
    tipsToSucceed,
    exerciseSleepAndRest,
    earlyDetectionIsKey,
    doctorVisit,
    participationPage,
    femaleHealthChecklist,
    maleHealthChecklist
  }) as GenerateResultsReportImagesConfig;
